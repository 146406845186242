export enum AccommodationTabs {
  invoices,
  guesthouse,
  logs,
}

export enum LocalStorageKeys {
  EMAIL = 'username',
  ID_TOKEN = 'idToken',
  TOKEN = 'accessToken',
}
