import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import './styles.scss';
import { Button, Spin } from 'antd';

import EditableTable from './EditableTable';
import { Paginator, SearchInput } from '../../components';
import { Serializer } from '../../common/utils';
import { useRepository } from '../../context';

import { IGuesthouseTable } from '../../common/interfaces/GuesthouseTable';
import { IGuesthouseUpdate } from '../../common/interfaces/GuesthousesAPI';

export const Guesthouse: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);

  const [data, setData] = useState<IGuesthouseTable[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [searchValue, setSearchValue] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [tableColumnOrder, setTableColumnOrder] = useState<string>('');
  const [tableEditing, setTableEditing] = useState<boolean>(false);

  const { guesthousesRepository } = useRepository();

  const { data: latestData, isLoading, isFetching, refetch } = useQuery(
    ['guesthouses', selectedPage, pageSize, tableColumnOrder],
    () => guesthousesRepository.getAllGuesthouses(selectedPage, pageSize, searchValue, tableColumnOrder),
    { refetchOnWindowFocus: false }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSaveHandler = (record: IGuesthouseUpdate): Promise<any> => guesthousesRepository.updateGuesthouseById(record);

  const onSearchClick = (): void => {
    if (selectedPage > 1) {
      setSelectedPage(1);
    } else {
      refetch();
    }
  };

  const onSearchResetHandler = (): void => {
    setSearchValue('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (p: any, f: any, sorter: any) => {
    setTableColumnOrder(`${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`);
  };

  useEffect(() => {
    if (latestData) {
      setData(latestData.results.map(Serializer.guesthousesToTable));
      setDataCount(latestData.count);
    }
  }, [latestData]);

  useEffect(() => {
    if (!searchValue.length) {
      refetch();
    }
  }, [searchValue]);

  return (
    <section className='guesthouse-container'>
      <div className='filters-bar'>
        <h3 className='filters-bar-title'>Search your Guesthouse</h3>
        <div className='row'>
          <div className='filters-bar-search'>
            <SearchInput
              placeholder='Search by Guesthouse ID, Guesthouse Location, Guesthouse…'
              value={searchValue}
              width={430}
              onChange={setSearchValue}
              onClick={onSearchClick}
            />
          </div>
          <Button className='filters-bar-btn-reset' type='link' onClick={onSearchResetHandler}>
            Reset Filter
          </Button>
          <span className='filters-bar-results'>{dataCount} Results</span>
        </div>
      </div>

      <div className='row'>
        <Spin size='large' spinning={isLoading || isFetching}>
          <EditableTable
            className={isLoading ? 'loading' : ''}
            data={data}
            onChange={handleTableChange}
            onEdit={(value) => setTableEditing(value)}
            onSave={(record: IGuesthouseUpdate) => onSaveHandler(record)}
          />
        </Spin>
      </div>

      <div className='row px-50'>
        <div className='update-info'></div>
        {dataCount > 10 && (
          <Paginator
            disabled={tableEditing}
            selectedPage={selectedPage}
            total={dataCount}
            onChangePage={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>
    </section>
  );
};

export default Guesthouse;
