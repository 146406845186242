import { IOption } from '../common/interfaces/IOption';

const statusOptions: IOption[] = [
  { value: '0', label: 'Error' },
  { value: '1', label: 'Rejected' },
  { value: '2', label: 'Not Confirmed' },
  { value: '3', label: 'Processed' },
  { value: '4', label: 'Approved' },
  { value: '5', label: 'Skipped' },
];

export default statusOptions;
