import React from 'react';
import { AccommodationTabs } from '../../common/enum';
import { useGlobalContext } from '../../context';
import { Navigation, Tabs } from '../../components';
import Guesthouse from '../Guesthouse';
import Invoices from '../Invoices';
import Logs from '../Logs';
import './styles.scss';

export const Accommodation: React.FC = () => {
  const { selectedTab } = useGlobalContext();

  const username = localStorage.getItem('username');
  const agency = localStorage.getItem('agency');

  return (
    <section className='page'>
      <Navigation />
      <div className='page-container'>
        <h1 className='page-title'>
          Hello {username} - {agency}
        </h1>
        <div className='content'>
          <Tabs />
          {selectedTab === AccommodationTabs.invoices && <Invoices />}
          {selectedTab === AccommodationTabs.logs && <Logs />}
          {selectedTab === AccommodationTabs.guesthouse && <Guesthouse />}
        </div>
      </div>
    </section>
  );
};

export default Accommodation;
