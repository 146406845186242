import { AxiosInstance } from 'axios';

class AxiosRepository {
  public constructor(private axiosClient: AxiosInstance) {}

  public get = this.axiosClient.get;
  public put = this.axiosClient.put;
}

export default AxiosRepository;
