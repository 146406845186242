import React, { FC, memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import '../styles.scss';
import moment from 'moment';

import { Spin, Table } from 'antd';

import Serializer from '../../../common/utils/serializer';
import { useRepository } from '../../../context';

import { ILogsTable } from '../../../common/interfaces/LogsTable';
import { IOption } from '../../../common/interfaces/IOption';
import statusOptions from '../../statuses';

const dateFormat = 'DD/MM/YYYY';

const columns = [
  {
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    title: '',
    width: 392,
  },
  {
    key: 'timestamp',
    title: '',
    width: 258,
    render: (record: ILogsTable) => ({
      children: <span>{moment(record.dateLog).format(dateFormat)}</span>,
    }),
  },
  {
    dataIndex: 'code',
    key: 'code',
    title: '',
    width: 118,
  },
  {
    dataIndex: 'message',
    key: 'message',
    title: '',
  },
  {
    key: 'id',
    title: '',
    width: 140,
    render: (record: ILogsTable) => ({
      children: getStatus(record),
    }),
  },
];

function getStatus(record: ILogsTable) {
  const status = statusOptions.find((item: IOption) => parseFloat(item.value) === record.status);

  return <div className='cell-status'>{status?.label}</div>;
}

interface ILogHistory {
  invoiceId: string;
}

export const LogHistory: FC<ILogHistory> = ({ invoiceId }: ILogHistory) => {
  const { logsRepository } = useRepository();

  const [data, setData] = useState<ILogsTable[]>([]);

  const { data: logHistory, isLoading } = useQuery(
    `log-${invoiceId}`,
    () => logsRepository.getLogByInvoiceId(invoiceId),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (logHistory && logHistory.length) {
      setData(logHistory.map(Serializer.logsToTable));
    }
  }, [logHistory]);

  return (
    <Spin size='large' tip='Loading...' spinning={isLoading}>
      <Table columns={columns} dataSource={data} pagination={false} style={{ width: '100%' }} />
    </Spin>
  );
};

export default memo(LogHistory);
