import React, { FC, memo, useEffect, useState } from 'react';
import '../styles.scss';
import moment from 'moment';

import { Table, Tooltip } from 'antd';

import { IInvoicesTable } from '../../../common/interfaces/InvoicesTable';

const dateFormat = 'DD/MM/YYYY';

const columns = [
  {
    key: 'number',
    title: 'Document \n Number',
    ellipsis: {
      showTitle: false,
    },
    width: 178,
    render: (record: IInvoicesTable) => ({
      children: <Tooltip title={record.relatedInvoiceNumber}>{record.relatedInvoiceNumber}</Tooltip>,
    }),
  },
  {
    key: 'guesthouse_agency_short_name',
    title: 'Guesthouse \n Agency',
    width: 90,
  },
  {
    dataIndex: 'amount',
    key: 'amount',
    title: 'Amount \n $',
    width: 70,
    render: (record: string) => ({
      children: `- ${record}`,
    }),
  },
  {
    key: 'gh_city_name',
    title: 'Guesthouse \n Location',
    width: 113,
  },
  {
    key: 'guesthouseCountry',
    title: 'Guesthouse \n Country',
    width: 113,
  },
  {
    title: 'Guest Email',
    width: 130,
  },
  {
    key: 'guestAgency',
    title: 'Guest \n Agency',
    width: 112,
  },
  {
    title: 'Reference Date',
    width: 100,
  },
  {
    key: 'created_at',
    title: 'Creation Date',
    width: 100,
    render: (record: IInvoicesTable) => ({
      children: <span>{moment(record.hbhCreditNoteIssuedDate).format(dateFormat)}</span>,
    }),
  },
  {
    key: 'status',
    title: 'Status',
  },
];

interface ICreditNotes {
  data: IInvoicesTable;
}

export const CreditNotes: FC<ICreditNotes> = ({ data }: ICreditNotes) => {
  const [creditNotes, setCreditNotes] = useState<IInvoicesTable[]>([]);

  useEffect(() => {
    if (data) {
      setCreditNotes([data]);
    }
  }, [data]);

  return <Table columns={columns} dataSource={creditNotes} pagination={false} style={{ width: '100%' }} />;
};

export default memo(CreditNotes);
