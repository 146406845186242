import React, { useEffect } from 'react';
import './styles.scss';
import { exchangeAuthCodeForTokens } from '../../common/utils/pkce';

export const CallbackPage: React.FC = () => {
  const retrieveToken = async () => {
    const currentUrl = new URL(window.location.toString());
    if (currentUrl.searchParams.has('code')) {
      const tokens = await exchangeAuthCodeForTokens();

      if (tokens) {
        window.location.href = '/';
      }
    }
  };

  useEffect(() => {
    retrieveToken();
  }, []);

  return <section className='login-container'></section>;
};

export default CallbackPage;
