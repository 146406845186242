import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import './styles.scss';

const Navigation: React.FC = () => {
  const history = useHistory();
  const goBack = () => history.goBack();

  return (
    <section className='navigation'>
      <div className='item' onClick={goBack}>
        <span className='item-icon'>
          <HomeIcon />
        </span>
      </div>
      <div className='item'>
        <span className='item-text'>Accommodation</span>
      </div>
    </section>
  );
};

export default Navigation;
