import React from 'react';
import './styles.scss';

interface ITextInput {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  width?: number;
  onChange?: (value: string) => void;
}

const TextInput: React.FC<ITextInput> = ({
  className = '',
  disabled = false,
  placeholder = '',
  value = '',
  width = 300,
  onChange,
}: ITextInput) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <span className={`input-container ${className ? className : ''}`} style={{ width }}>
      <input
        type='text'
        className='input-field'
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </span>
  );
};

export default TextInput;
