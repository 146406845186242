import { IClearingHouse } from '../interfaces/GuesthousesAPI';
import { IGuesthouseTable } from '../interfaces/GuesthouseTable';
import { IInvoice } from '../interfaces/InvoicesAPI';
import { IInvoicesTable } from '../interfaces/InvoicesTable';
import { ILog } from '../interfaces/LogsAPI';
import { ILogsTable } from '../interfaces/LogsTable';

class Serializer {
  public static guesthousesToTable(ch: IClearingHouse): IGuesthouseTable {
    return {
      city_name: ch.guesthouse.city_name,
      country_name: ch.guesthouse.country_name,
      external_identifier: ch.external_identifier,
      guesthouse_id: ch.guesthouse.id,
      id: ch.id,
      name: ch.guesthouse.name,
      ...(Object.keys(ch).includes('expiry_date') ? { expiry_date: ch.expiry_date ? ch.expiry_date : '' } : {}),
    };
  }

  public static invoicesToTable(inv: IInvoice): IInvoicesTable {
    return {
      amount: inv.amount_usd,
      clearingHouseDate: inv.hbh_reference_date,
      createdAt: inv.created_at || '',
      guestAgency: inv.guest_agency_short_name,
      guestEmail: inv.guest_email_address || '',
      guesthouseAgency: inv.guesthouse_agency_short_name || '',
      guesthouseCountry: inv.gh_country || '',
      guesthouseLocation: inv.gh_city_name || '',
      hbhCreditNoteIssuedDate: inv.hbh_credit_note_issued_date || '',
      identifier: inv.identifier,
      invoiceDate: inv.depart_date || '',
      invoiceNumber: inv.number,
      invoiceType: inv.invoice_type,
      key: inv.identifier,
      relatedInvoiceNumber: inv.related_invoice_number,
      status: inv.last_log.status,
    };
  }

  public static logsToTable(log: ILog): ILogsTable {
    return {
      code: log.response_code,
      dateLog: log.timestamp,
      id: log.invoice_id,
      invoiceNumber: log.invoice_number,
      message: log.message,
      key: log.identifier,
      status: log.status,
    };
  }
}

export default Serializer;
