import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Header } from './components';
import { Accommodation, CallbackPage, Forbidden, LandingPage, Logout, NotFound, SignIn } from './main';
import { GlobalProvider, RepositoryProvider } from './context';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const authState = localStorage.getItem('accessToken');

  return (
    <QueryClientProvider client={queryClient}>
      <RepositoryProvider>
        <GlobalProvider>
          {authState ? (
            <Router>
              {window.location.pathname !== '/forbidden' ? <Header /> : null}
              <Switch>
                <Route exact path='/' component={LandingPage} />
                <Route exact path='/accommodation' component={Accommodation} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/forbidden' component={Forbidden} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          ) : (
            <Router>
              <Switch>
                <Route exact path='/' component={SignIn} />
                <Route path='/callback' component={CallbackPage} />
                <Route exact path='/forbidden' component={Forbidden} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          )}
        </GlobalProvider>
      </RepositoryProvider>
    </QueryClientProvider>
  );
};

export default App;
