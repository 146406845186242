import React, { useEffect, useState } from 'react';
import './styles.scss';

import { useHistory } from 'react-router-dom';
import { useRepository } from '../../context';
import { refreshAccessToken } from '../../common/utils/pkce';

import { Button } from 'antd';
import { ReactComponent as ClearingHouseIcon } from '../../assets/svg/ch.svg';
import { ReactComponent as DocumentIcon } from '../../assets/svg/documents.svg';
import { ReactComponent as ManualIcon } from '../../assets/svg/manuals.svg';
import Maintenance from '../../components/Maintenance';

export const LandingPage: React.FC = () => {
  const agency = localStorage.getItem('agency');
  const history = useHistory();
  const { userRepository } = useRepository();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToPage = (page: string): void => {
    history.push(`/${page}`);
  };

  const checkPermissions = async () => {
    //TODO: check user Permissions if token cognito:groups haven't 'FEDERATED_ALLOWED_USERS_dev'
    const userHasAccess = await userRepository.checkUserPermissions();
    if (userHasAccess === 200) {
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        goToPage('accommodation');
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    setIsLoading(true);
    checkPermissions();
  };

  useEffect(() => {
    const goToNextPage = localStorage.getItem('invalid_grant');

    if (goToNextPage) {
      localStorage.removeItem('invalid_grant');
      goToPage('accommodation');
    }
  }, []);

  return (
    <section className='landing-page-container'>
      <Maintenance switchName='maintenance_mode_clearing_house' />
      <div className='lp-panel'>
        <div className='lp-panel-title'>Clearing House - {agency}</div>
        <div className='lp-panel-content'>
          <div className='row'>
            <Button
              block
              icon={<ClearingHouseIcon />}
              loading={isLoading}
              size='large'
              type='primary'
              onClick={handleClick}
            >
              Accommodation
            </Button>
          </div>
          <hr />
          <div className='row'>
            <Button block icon={<ManualIcon />} size='large' type='default' onClick={() => goToPage('manuals')}>
              Manuals
            </Button>
            <Button block icon={<DocumentIcon />} size='large' type='default' onClick={() => goToPage('documents')}>
              Documents
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LandingPage;
