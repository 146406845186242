import React from 'react';
import { AccommodationTabs } from '../../common/enum';
import { useGlobalContext } from '../../context';
import './styles.scss';

interface ITab {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab: React.FC<ITab> = ({ title, isActive, onClick }: ITab) => {
  return (
    <div className={!isActive ? 'tab' : 'tab-active'} onClick={onClick}>
      <div className='tab-title'>{title}</div>
    </div>
  );
};

const Tabs: React.FC = () => {
  const { selectedTab, setSelectedTab } = useGlobalContext();

  const chooseInvoicesTab = () => setSelectedTab(AccommodationTabs.invoices);
  const chooseLogsTab = () => setSelectedTab(AccommodationTabs.logs);
  const chooseGuesthouseTab = () => setSelectedTab(AccommodationTabs.guesthouse);

  return (
    <section className='tabs'>
      <Tab isActive={selectedTab === AccommodationTabs.invoices} title='Invoices' onClick={chooseInvoicesTab} />
      <Tab isActive={selectedTab === AccommodationTabs.logs} title='Logs' onClick={chooseLogsTab} />
      <Tab isActive={selectedTab === AccommodationTabs.guesthouse} title='Guesthouse' onClick={chooseGuesthouseTab} />
    </section>
  );
};

export default Tabs;
