import React, { FC } from 'react';
import './styles.scss';

import { Select } from 'antd';
import { IOption } from '../../common/interfaces/IOption';
import { ReactComponent as ArrowDownIcon } from '../../assets/svg/dropdown_arrow_down.svg';

interface ISelect {
  className?: 'default' | 'outline';
  disabled?: boolean;
  mode?: 'multiple' | 'tags';
  options?: IOption[];
  placeholder?: string;
  styles?: { [key: string]: string | number };
  value?: IOption | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
}
const Dropdown: FC<ISelect> = ({
  className = 'default',
  disabled = false,
  mode,
  options,
  placeholder = '',
  styles,
  value,
  onChange,
  ...props
}: ISelect) => {
  return (
    <Select
      className={`select ${className}`}
      disabled={disabled}
      mode={mode}
      options={options}
      placeholder={placeholder}
      style={styles}
      suffixIcon={<ArrowDownIcon />}
      value={value}
      {...props}
      onChange={(value) => {
        onChange && onChange(value);
      }}
    />
  );
};

export default Dropdown;
