import React from 'react';
import './styles.scss';

import { Button } from 'antd';
import { ReactComponent as Logo } from '../../assets/svg/ch_logo.svg';

export const Forbidden: React.FC = () => {
  const goBack = (): void => {
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_BASE_URL}`;
  };

  return (
    <section className='container forbidden'>
      <div className='panel'>
        <div className='row'>
          <Logo className='logo' />
        </div>
        <div className='row'>
          <div className='message'>
            <p>Clearing House reserved area.</p>
            <p>
              If you need access to the automated cashless payments area, please contact your country&rsquo;s Finance
              Office and c.c. <a href='mailto: global.accommodation@wfp.org'>global.accommodation@wfp.org</a>
            </p>
            <br />
            <p>
              In case you have received the authorisation and it is your first access attempt, please check your email
              account for confirmation.
            </p>
            <p>
              Thank you
              <br />
              Global Accommodation team
              <br />
              <a href='mailto: global.accommodation@wfp.org'>global.accommodation@wfp.org</a>
            </p>
          </div>
        </div>
        <div className='row'>
          <Button size='large' onClick={goBack}>
            Go back to HBH
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Forbidden;
