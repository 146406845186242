import { LocalStorageKeys } from '../../common/enum';

export async function getSwitches() {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/core/feature-flags/`, {
      method: 'GET',
      headers: {
        Authentication: localStorage.getItem(LocalStorageKeys.ID_TOKEN)!,
      },
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    const json = await response.json();
    return json;
  } catch (error) {
    throw console.error(error);
  }
}
