import React, { createContext, ReactNode, useContext } from 'react';
import { GuesthousesRepository, InvoicesRepository, LogsRepository, UserRepository } from '../common/repositories';
import { axiosInstance } from '../common/utils';

const repositories = {
  guesthousesRepository: new GuesthousesRepository(axiosInstance),
  invoicesRepository: new InvoicesRepository(axiosInstance),
  logsRepository: new LogsRepository(axiosInstance),
  userRepository: new UserRepository(axiosInstance),
};

const RepositoryContext = createContext(repositories);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function RepositoryProvider({ children }: { children: ReactNode }) {
  return <RepositoryContext.Provider value={repositories}>{children}</RepositoryContext.Provider>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
