import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import './styles.scss';
import moment from 'moment';

import { Button, Spin, Table } from 'antd';
import { SortOrder } from 'antd/es/table/interface';

import { DatePickerInput, Paginator, SearchInput } from '../../components';
import { Serializer } from '../../common/utils';
import { useRepository } from '../../context';

import { ILogsTable } from '../../common/interfaces/LogsTable';
import { IOption } from '../../common/interfaces/IOption';
import statusOptions from '../statuses';

import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import LogHistory from './logHistory';

const dateFormat = 'DD/MM/YYYY';

const columns = [
  {
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    title: 'Invoice Number',
  },
  {
    key: 'timestamp',
    title: 'Date of the Log',
    render: (record: ILogsTable) => ({
      children: <span>{moment(record.dateLog).format(dateFormat)}</span>,
    }),
  },
  {
    dataIndex: 'code',
    key: 'code',
    title: 'Code',
  },
  {
    dataIndex: 'message',
    key: 'message',
    title: 'Message',
  },
  {
    key: 'id',
    title: 'Status',
    width: 140,
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    render: (record: ILogsTable) => ({
      children: getStatus(record),
    }),
  },
];

function getStatus(record: ILogsTable) {
  const status = statusOptions.find((item: IOption) => parseFloat(item.value) === record.status);

  return <div className='cell-status'>{status?.label}</div>;
}

export const Logs: React.FC = () => {
  const { logsRepository } = useRepository();
  const [pageSize, setPageSize] = useState<number>(10);

  const [data, setData] = useState<ILogsTable[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState(1);

  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [tableColumnOrder, setTableColumnOrder] = useState<string>('');

  const { data: latestData, isLoading, isFetching, refetch: getLogs } = useQuery(
    ['logs', selectedPage, pageSize, tableColumnOrder],
    () => logsRepository.getAllLogs(selectedPage, pageSize, searchValue, startDate, endDate, tableColumnOrder),
    { refetchOnWindowFocus: false }
  );

  const { refetch: getCSVFile, isLoading: isFileLoading } = useQuery(
    ['invoicesCsv', selectedPage, pageSize, tableColumnOrder],
    () => logsRepository.getLogsCsv(searchValue, startDate, endDate),
    {
      enabled: false,
      refetchInterval: false,
    }
  );

  const handleExportCsv = async () => {
    await getCSVFile();
  };

  const onDateRangeSearchHandler = (): void => {
    if (startDate && endDate) {
      if (selectedPage > 1) {
        setSelectedPage(1);
      } else {
        getLogs();
      }
    }
  };

  const onSearchHandler = (): void => {
    if (selectedPage > 1) {
      setSelectedPage(1);
    } else {
      getLogs();
    }
  };

  const onSearchReset = (): void => {
    setSearchValue('');
  };

  const onDateRangeSearchReset = (): void => {
    setStartDate('');
    setEndDate('');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (p: any, f: any, sorter: any) => {
    setTableColumnOrder(`${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`);
  };

  useEffect(() => {
    if (latestData) {
      setData(latestData.results.map(Serializer.logsToTable));
      setDataCount(latestData.count);
    }
  }, [latestData]);

  useEffect(() => {
    if (searchValue.length) {
      setStartDate('');
      setEndDate('');
    } else {
      getLogs();
    }
  }, [searchValue]);

  useEffect(() => {
    if (startDate.length || endDate.length) {
      if (searchValue.length) {
        setSearchValue('');
      }
    } else {
      getLogs();
    }
  }, [startDate, endDate]);

  return (
    <section className='logs-container'>
      <div className='row'>
        <div className='filters-bar'>
          <div className='filters-bar-main'>
            <div className='form-field search-field'>
              <label>Search your Logs</label>
              <SearchInput
                placeholder='Search by Invoice Number'
                value={searchValue}
                width={350}
                onChange={setSearchValue}
                onClick={onSearchHandler}
              />
              <Button className='btn-reset block-right' type='link' onClick={onSearchReset}>
                Reset Filter
              </Button>
            </div>

            <div className='date-range'>
              <div className='form-field date-field'>
                <label>From</label>
                <DatePickerInput
                  className='outline'
                  dateFormat='DD/MM/YYYY'
                  value={startDate}
                  onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))}
                />
              </div>
              <div className='form-field date-field'>
                <label>To</label>
                <DatePickerInput
                  className='outline'
                  dateFormat='DD/MM/YYYY'
                  value={endDate}
                  onChange={(date) => setEndDate(moment(date).format('YYYY-MM-DD'))}
                />
              </div>
              <div className='form-field no-label'>
                <Button className='btn-search' icon={<SearchIcon />} type='primary' onClick={onDateRangeSearchHandler}>
                  Search
                </Button>
              </div>
              <div className='form-field no-label'>
                <Button className='btn-reset' type='link' onClick={onDateRangeSearchReset}>
                  Reset Filter
                </Button>
              </div>
              <div className='form-field no-label'>
                <span className='count'>{dataCount} Results</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <Spin size='large' spinning={isLoading || isFetching || isFileLoading}>
          <Table
            columns={columns}
            className={isLoading ? 'loading' : ''}
            dataSource={data}
            expandable={{
              // eslint-disable-next-line react/display-name
              expandedRowRender: (record: ILogsTable) => <LogHistory invoiceId={record.id} />,
            }}
            pagination={false}
            style={{ width: '100%' }}
            onChange={handleTableChange}
          />
        </Spin>
      </div>

      <div className='row px-50'>
        <div>
          {dataCount > 0 && (
            <Button type='primary' onClick={handleExportCsv}>
              Download CSV Logs
            </Button>
          )}
        </div>
        {dataCount > 10 && (
          <Paginator
            selectedPage={selectedPage}
            total={dataCount}
            onChangePage={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>
    </section>
  );
};

export default Logs;
