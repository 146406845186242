import AxiosRepository from './axios.repository';
import { AxiosInstance } from 'axios';

class UserRepository extends AxiosRepository {
  public constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  public async checkUserPermissions(): Promise<unknown> {
    const { status } = await this.get(`/locations/clearing-house/check-permissions/`);

    return status;
  }
}

export default UserRepository;
