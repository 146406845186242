import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import './styles.scss';

interface ISearchInput {
  className?: string;
  placeholder?: string;
  value: string;
  width?: number;
  onChange: (value: string) => void;
  onClick?: () => void;
}

const SearchInput: React.FC<ISearchInput> = ({
  className,
  placeholder = 'Search',
  value,
  width = 300,
  onChange,
  onClick,
}: ISearchInput) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <span className={`search-input-container ${className ? className : ''}`} style={{ width }}>
      <input type='text' className='search-input' placeholder={placeholder} value={value} onChange={handleChange} />
      <span className='search-input-btn' onClick={() => onClick && onClick()}>
        <SearchIcon />
      </span>
    </span>
  );
};

export default SearchInput;
