import jwt_decode from 'jwt-decode';

const env = process.env.REACT_APP_ENV;
const clearingHouseDomain =
  env === 'prod'
    ? 'https://api-clearing-house.humanitarianbooking.wfp.org'
    : `https://api.${process.env.REACT_APP_ENV}.clearing-house.unbooking.org`;
const config = {
  authorization_endpoint: `${process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT}`,
  client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
  token_endpoint: `${process.env.REACT_APP_AUTH_TOKEN_ENDPOINT}`,
  logout_endpoint: `${process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT}`,
  logout_uri: `${process.env.REACT_APP_AUTH_LOGOUT_URI}`,
  scopes: ['email', `${clearingHouseDomain}/invoices.read`, `${clearingHouseDomain}/logs.read`, 'openid', 'profile'],
};

type TokenPayload = {
  email: string;
  exp: string;
  preferred_username: string;
  agency: string;
};

const accessToken = localStorage.getItem('accessToken');
const idToken = localStorage.getItem('idToken');
const refreshToken = localStorage.getItem('refreshToken');
const provider = localStorage.getItem('provider');

let tokens =
  accessToken === null
    ? null
    : {
        accessToken,
        idToken,
        refreshToken,
        provider,
      };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTokens = () => tokens;

export const deleteTokens = (): void => {
  // const goBackLink = localStorage.getItem('goBackLink') || '';
  // const url = `${process.env.REACT_APP_BASE_URL}${goBackLink}`;
  localStorage.clear();
  tokens = null;
  window.location.href = `${process.env.REACT_APP_BASE_URL}`;
};

export const logout = async (): Promise<void> => {
  const url = `${config.logout_endpoint}?response_type=code&client_id=${config.client_id}&logout_uri=${config.logout_uri}`;
  window.location.href = url;
};

// Generate a secure random string using the browser crypto functions
export const generatePkceAuthUrl = async (paramProvider: unknown): Promise<string> => {
  // Create and store a random "state" value
  const state = generateRandomString();
  localStorage.setItem('pkce_state', state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const code_verifier = generateRandomString();
  localStorage.setItem('pkce_code_verifier', code_verifier);

  // Hash and base64-urlencode the secret to use as the challenge
  const arrayHash = await encryptStringWithSHA256(code_verifier);
  const code_challenge = hashToBase64url(arrayHash);

  const params = `identity_provider=${paramProvider}&response_type=code&client_id=${
    config.client_id
  }&state=${state}&scope=${config.scopes.join(' ')}&redirect_uri=${
    config.redirect_uri
  }&code_challenge=${code_challenge}&code_challenge_method=S256`;

  // Build the authorization URL
  return `${config.authorization_endpoint}?${params}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const exchangeAuthCodeForTokens = async () => {
  const callbackUrl = new URLSearchParams(window.location.search);

  const state = localStorage.getItem('pkce_state');
  const code_verifier = localStorage.getItem('pkce_code_verifier');

  if (callbackUrl.has('error')) {
    const error = callbackUrl.get('error');
    throw new Error(`Error returned from authorization server: ${error}`);
  }

  // Exchange code for our tokens
  if (callbackUrl.has('code')) {
    if (callbackUrl.get('state') !== state) {
      throw new Error(`Invalid state`);
    }

    const code = callbackUrl.get('code');
    const url = `${config.token_endpoint}?grant_type=authorization_code&client_id=${config.client_id}&code_verifier=${code_verifier}&redirect_uri=${config.redirect_uri}&code=${code}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const { access_token, id_token, refresh_token } = await response.json();

    if (access_token && id_token && refresh_token) {
      const { agency, email, exp, preferred_username } = jwt_decode<TokenPayload>(id_token);
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('expiration', exp);
      localStorage.setItem('idToken', id_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('email', email);
      localStorage.setItem('username', preferred_username);
      localStorage.setItem('agency', agency);
    }

    localStorage.removeItem('pkce_state');
    localStorage.removeItem('pkce_code_verifier');

    tokens = {
      accessToken: access_token,
      idToken: id_token,
      refreshToken,
      provider,
    };

    return access_token && refresh_token;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const refreshAccessToken = async () => {
  const url = `${config.token_endpoint}?grant_type=refresh_token&client_id=${config.client_id}&refresh_token=${refreshToken}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  const status = response.status;
  const { access_token, id_token } = await response.json();

  if (status === 200 && access_token && id_token) {
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('idToken', id_token);
    return access_token;
  }

  if (status === 400) {
    const provider = localStorage.getItem('provider');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.setItem('invalid_grant', 'error');

    const loginUrl = await generatePkceAuthUrl(provider);

    if (loginUrl) {
      window.location.href = loginUrl;
    }
  }

  return '';
};

// PKCE HELPER FUNCTIONS

// Generate a secure random string using the browser crypto functions
const generateRandomString = (): string => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  // @ts-ignore
  const binaryStringItems = randomItems.map((dec) => `0${dec.toString(16).substr(-2)}`);

  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
};

const encryptStringWithSHA256 = (str: string) => {
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);

  return crypto.subtle.digest('SHA-256', encodedData);
};

// Convert Hash to Base64-URL
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hashToBase64url = (arrayBuffer: any) => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
  const decodedHash = btoa(stringifiedArrayHash);

  return decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};
