/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AccommodationTabs } from '../common/enum';

const initialState = {
  selectedTab: AccommodationTabs.invoices,
  tabsNumbers: [0, 0],
  setTabNumbers: (_: [number, number]) => {},
  setSelectedTab: (_: AccommodationTabs) => {},
};

const GlobalContext = createContext(initialState);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function GlobalProvider({ children }: { children: ReactNode }) {
  const [tabsNumbers, setTabNumbers] = useState([0, 0]);
  const [selectedTab, setSelectedTab] = useState<AccommodationTabs>(AccommodationTabs.invoices);

  return (
    <GlobalContext.Provider
      value={{
        tabsNumbers,
        selectedTab,
        setSelectedTab,
        setTabNumbers,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
