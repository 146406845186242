import React, { FC } from 'react';
import './styles.scss';

import { Pagination } from 'antd';

export type pageSizeType = 10 | 20 | 50 | 100;

interface IPaginator {
  disabled?: boolean;
  selectedPage?: number;
  total: number;
  onChangePage?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
}

const Paginator: FC<IPaginator> = ({
  disabled = false,
  selectedPage = 1,
  total,
  onChangePage,
  onPageSizeChange,
}: IPaginator) => {
  return (
    <Pagination
      disabled={disabled}
      current={selectedPage}
      showSizeChanger
      total={total}
      onChange={onChangePage}
      onShowSizeChange={(_, size) => {
        onPageSizeChange && onPageSizeChange(size);
      }}
    />
  );
};

export default Paginator;
