import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import './styles.scss';

import { generatePkceAuthUrl } from '../../common/utils/pkce';

export const SignIn: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramBackLink = query.get('from');
  const paramProvider = query.get('provider');

  const onLoginHandler = async () => {
    const loginUrl = await generatePkceAuthUrl(paramProvider);

    if (loginUrl) {
      window.location.href = loginUrl;
    }
  };

  useEffect(() => {
    if (paramBackLink && paramProvider) {
      localStorage.setItem('goBackLink', paramBackLink);
      localStorage.setItem('provider', paramProvider);
      onLoginHandler();
    }
  }, []);

  return (
    <section className='login-container'>
      <div className='login-panel'>
        <div className='row'>
          <span className='login-greeting'>
            Welcome to the <strong>Clearing House</strong>
          </span>
        </div>
        <div className='row'>
          <Spin size='large'></Spin>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
