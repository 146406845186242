import AxiosRepository from './axios.repository';
import { AxiosInstance } from 'axios';
import { IInvoicesRepository } from '../interfaces/InvoicesRepository';
import { IInvoicesResponse } from '../interfaces/InvoicesAPI';
import FileSaver from 'file-saver';

class InvoicesRepository extends AxiosRepository implements IInvoicesRepository {
  public constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  public async getAllInvoices(
    advancedFilterParams: string,
    page: number,
    pageSize: number,
    searchValue: string,
    startDate: string,
    endDate: string,
    order: string
  ): Promise<IInvoicesResponse> {
    const params: { [key: string]: string } = {};
    if (searchValue) params.search = searchValue.trim();
    if (order) params.ordering = order.trim();

    let url = '/invoices/';
    if (startDate && endDate) url += `?start_hbh_reference_date=${startDate}&end_hbh_reference_date=${endDate}`;
    if (startDate && endDate && advancedFilterParams) url += `&${advancedFilterParams}`;

    const { data } = await this.get(url, {
      params: { page, page_size: pageSize, ...(Object.keys(params).length ? params : {}) },
    });

    return data;
  }

  public async getCreditNotes(invoiceId: string, invoiceType: number): Promise<IInvoicesResponse> {
    const { data } = await this.get(`/invoices/?invoice_type=${invoiceType}&related_invoice_number=${invoiceId}`);

    return data;
  }

  public async getInvoicesCsv(
    advancedFilterParams: string,
    searchValue: string,
    startDate: string,
    endDate: string
  ): Promise<void> {
    const params: { [key: string]: string } = {};
    if (searchValue) params.search = searchValue.trim();
    params.export = 'export';

    let url = '/invoices/';
    if (startDate && endDate) url += `?start_hbh_reference_date=${startDate}&end_hbh_reference_date=${endDate}`;
    if (startDate && endDate && advancedFilterParams) url += `&${advancedFilterParams}`;

    const { data, headers } = await this.get(url, {
      params: { ...(Object.keys(params).length ? params : {}) },
      responseType: 'blob',
    });

    const filename = headers['content-disposition'].split(';')[1].split('=')[1].split('"')[1].trim();
    FileSaver.saveAs(data, filename);
  }
}

export default InvoicesRepository;
