import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import './styles.scss';

import { Button, notification, Spin, Table, Tooltip } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import { IInvoicesTable } from '../../common/interfaces/InvoicesTable';
import { IOption } from '../../common/interfaces/IOption';

import { DatePickerInput, Dropdown, Paginator, SearchInput, TextInput } from '../../components';
import { Serializer } from '../../common/utils';
import { useRepository } from '../../context';
import CreditNotes from './CreditNotes';
import statusOptions from '../statuses';

import { ReactComponent as ArrowDownIcon } from '../../assets/svg/dropdown_arrow_down.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';

const dateFormat = 'DD/MM/YYYY';

const columns = [
  {
    ellipsis: {
      showTitle: false,
    },
    key: 'number',
    title: 'Document \n Number',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 130,
    render: (record: IInvoicesTable) => ({
      children: <Tooltip title={record.invoiceNumber}>{record.invoiceNumber}</Tooltip>,
    }),
  },
  {
    ellipsis: {
      showTitle: false,
    },
    key: 'guesthouse_agency_short_name',
    title: 'Guesthouse \n Agency',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 90,
    render: (record: IInvoicesTable) => ({
      children: <Tooltip title={record.guesthouseAgency}>{record.guesthouseAgency}</Tooltip>,
    }),
  },
  {
    dataIndex: 'amount',
    key: 'amount',
    title: 'Amount \n $',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 70,
  },
  {
    dataIndex: 'guesthouseLocation',
    key: 'gh_city_name',
    title: 'Guesthouse \n Location',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
  },
  {
    dataIndex: 'guesthouseCountry',
    key: 'gh_country',
    title: 'Guesthouse \n Country',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
  },
  {
    key: 'guestEmail',
    title: 'Guest Email',
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 130,
    render: (record: IInvoicesTable) => ({
      children: <Tooltip title={record.guestEmail}>{record.guestEmail}</Tooltip>,
    }),
  },
  {
    dataIndex: 'guestAgency',
    key: 'guest_agency_short_name',
    title: 'Guest \n Agency',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
  },
  {
    key: 'hbh_reference_date',
    title: 'Reference Date',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 100,
    render: (record: IInvoicesTable) => ({
      children: <span>{moment(record.clearingHouseDate).format(dateFormat)}</span>,
    }),
  },
  {
    key: 'created_at',
    title: 'Creation Date',
    sortDirections: ['descend', 'ascend'] as SortOrder[],
    sorter: true,
    width: 100,
    render: (record: IInvoicesTable) => ({
      children: <span>{moment(record.createdAt).format(dateFormat)}</span>,
    }),
  },
  {
    key: 'status',
    title: 'Status',
    sorter: false,
    render: (record: IInvoicesTable) => ({
      children: getStatus(record),
    }),
  },
];

function getStatus(record: IInvoicesTable) {
  const status = statusOptions.find((item: IOption) => parseFloat(item.value) === record.status);

  //return <div className={`cell-status ${record.status}`}>{status?.label}</div>;
  return <div className='cell-status'>{status?.label}</div>;
}

export const Invoices: React.FC = () => {
  const { invoicesRepository } = useRepository();
  const [pageSize, setPageSize] = useState<number>(10);

  const [data, setData] = useState<IInvoicesTable[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [isAdvancedFiltersOpen, setIsAdvancedFiltersOpen] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [filterByEmail, setFilterByEmail] = useState<string>('');
  const [filterByGuesthouseCountry, setFilterByGuesthouseCountry] = useState<string>('');
  const [filterByGuestAgency, setFilterByGuestAgency] = useState<IOption | undefined>(undefined);
  const [filterByGuesthouseAgency, setFilterByGuesthouseAgency] = useState<string>('');
  const [filterByStatus, setFilterByStatus] = useState<string[]>([]);
  const [tableColumnOrder, setTableColumnOrder] = useState<string>('');

  const guestAgency: IOption[] = [
    { value: 'WFP', label: 'WFP' },
    { value: 'UNICEF', label: 'UNICEF' },
  ];

  const statuses: IOption[] = statusOptions;

  const getAdvancedFilterParams = (): string => {
    const email = filterByEmail ? `guest_email_address=${filterByEmail}&` : '',
      guesthouseCountry = filterByGuesthouseCountry ? `gh_country=${filterByGuesthouseCountry}&` : '',
      guestAgency = filterByGuestAgency ? `guest_agency_short_name=${filterByGuestAgency}&` : '',
      guesthouseAgency = filterByGuesthouseAgency ? `guesthouse_agency_short_name=${filterByGuesthouseAgency}&` : '',
      status = filterByStatus.length > 0 ? `status=${filterByStatus}` : '';

    return `${email}${guesthouseCountry}${guestAgency}${guesthouseAgency}${status}`;
  };

  const { data: latestData, isLoading, isFetching, refetch: getInvoices } = useQuery(
    ['invoices', selectedPage, pageSize, tableColumnOrder],
    () =>
      invoicesRepository.getAllInvoices(
        getAdvancedFilterParams(),
        selectedPage,
        pageSize,
        searchValue,
        startDate,
        endDate,
        tableColumnOrder
      ),
    { refetchOnWindowFocus: false }
  );

  const { refetch: getCSVFile, isLoading: isFileLoading } = useQuery(
    ['invoicesCsv', selectedPage, pageSize, tableColumnOrder],
    () => invoicesRepository.getInvoicesCsv(getAdvancedFilterParams(), searchValue, startDate, endDate),
    {
      enabled: false,
      refetchInterval: false,
    }
  );

  const handleExportCsv = async () => {
    await getCSVFile();
  };

  const onSearchNumberClick = (): void => {
    if (selectedPage > 1) {
      setSelectedPage(1);
    } else {
      getInvoices();
    }
  };

  const onSearchNumberReset = (): void => {
    setSearchValue('');
  };

  const onAdvancedSearchClick = (): void => {
    if (startDate && endDate) {
      if (selectedPage > 1) {
        setSelectedPage(1);
      } else {
        getInvoices();
      }
    }
  };

  const clearAdvancedFilters = (): void => {
    setFilterByEmail('');
    setFilterByGuesthouseCountry('');
    setFilterByGuestAgency(undefined);
    setFilterByGuesthouseAgency('');
    setFilterByStatus([]);
  };

  const onAdvancedFiltersReset = (): void => {
    clearAdvancedFilters();
    setStartDate('');
    setEndDate('');
  };

  const onShowAdvancedFilters = (): void => {
    clearAdvancedFilters();
    setIsAdvancedFiltersOpen(!isAdvancedFiltersOpen);
  };

  const isFiltersDisabled = (): boolean => !(startDate && endDate);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (p: any, f: any, sorter: any) => {
    const url = `${sorter.order === 'descend' ? '-' : ''}${sorter.columnKey}`;

    setTableColumnOrder(url);
  };

  useEffect(() => {
    if (latestData) {
      setData(latestData.results.map(Serializer.invoicesToTable));
      setDataCount(latestData.count);
    }
  }, [latestData]);

  useEffect(() => {
    if (searchValue.length) {
      clearAdvancedFilters();
      setStartDate('');
      setEndDate('');
    } else {
      getInvoices();
    }
  }, [searchValue]);

  useEffect(() => {
    if (startDate.length || endDate.length) {
      if (searchValue.length) {
        setSearchValue('');
      }
    } else {
      getInvoices();
    }
  }, [startDate, endDate]);

  return (
    <section className='invoices-container'>
      <div className='row'>
        <div className='filters-bar'>
          <div className='filters-bar-main'>
            <div className='form-field search-field'>
              <label>Search by Document Number</label>
              <SearchInput
                placeholder='Write document number'
                value={searchValue}
                width={430}
                onChange={setSearchValue}
                onClick={onSearchNumberClick}
              />
              <Button className='btn-reset block-right' type='link' onClick={onSearchNumberReset}>
                Reset Filter
              </Button>
            </div>

            <div className={`date-range ${isAdvancedFiltersOpen ? 'advanced-filters-is-open' : ''}`}>
              <div className='form-field date-field'>
                <label>From</label>
                <DatePickerInput
                  className='outline'
                  dateFormat='DD/MM/YYYY'
                  value={startDate}
                  onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))}
                />
              </div>
              <div className='form-field date-field'>
                <label>To</label>
                <DatePickerInput
                  className='outline'
                  dateFormat='DD/MM/YYYY'
                  value={endDate}
                  onChange={(date) => setEndDate(moment(date).format('YYYY-MM-DD'))}
                />
              </div>
              <div className='form-field no-label'>
                <Button
                  className='btn-search'
                  icon={<SearchIcon />}
                  disabled={isFiltersDisabled()}
                  type='primary'
                  onClick={onAdvancedSearchClick}
                >
                  Search
                </Button>
              </div>
              <div className='form-field no-label'>
                <span className='count'>{dataCount} Results</span>
              </div>
              <div
                className='show-more-filters'
                onClick={() => {
                  if (!isFiltersDisabled()) {
                    onShowAdvancedFilters();
                  } else {
                    notification.info({ message: 'Please choose From and To date' });
                  }
                }}
              >
                {isAdvancedFiltersOpen ? 'Close Filters' : 'More filters'}
                <span className='show-more-filters-icon'>
                  <ArrowDownIcon />
                </span>
              </div>
            </div>
          </div>

          {isAdvancedFiltersOpen && (
            <div className='filters-bar-advanced'>
              <div className='row'>
                <div className='form-field'>
                  <label>by Email</label>
                  <TextInput
                    className='outline'
                    placeholder='Write email'
                    value={filterByEmail}
                    width={230}
                    onChange={(value) => setFilterByEmail(value)}
                  ></TextInput>
                </div>

                <div className='form-field'>
                  <label>by Guesthouse Country</label>
                  <TextInput
                    className='outline'
                    placeholder='Choose Country'
                    value={filterByGuesthouseCountry}
                    width={170}
                    onChange={(value) => setFilterByGuesthouseCountry(value)}
                  ></TextInput>
                </div>

                <div className='form-field'>
                  <label>by Guest Agency</label>
                  <Dropdown
                    className='outline'
                    options={guestAgency}
                    placeholder='Search By Guest Agency'
                    styles={{ width: 170, height: 30 }}
                    value={filterByGuestAgency}
                    onChange={(value) => setFilterByGuestAgency(value)}
                  ></Dropdown>
                </div>

                <div className='form-field'>
                  <label>by Guesthouse Agency</label>
                  <TextInput
                    className='outline'
                    placeholder='Search By Agency'
                    value={filterByGuesthouseAgency}
                    width={170}
                    onChange={(value) => setFilterByGuesthouseAgency(value)}
                  ></TextInput>
                </div>

                <div className='form-field'>
                  <label>Search by Status</label>
                  <Dropdown
                    className='outline'
                    options={statuses}
                    mode='multiple'
                    placeholder='All'
                    styles={{ width: 170, minHeight: 30 }}
                    onChange={(value) => setFilterByStatus(value)}
                  ></Dropdown>
                </div>
              </div>
              <div className='row filters-bar-advanced-control'>
                <Button className='btn-reset mx-25' type='link' onClick={onAdvancedFiltersReset}>
                  Reset Filter
                </Button>

                <Button
                  className='btn-search'
                  disabled={isFiltersDisabled()}
                  type='primary'
                  onClick={onAdvancedSearchClick}
                >
                  Add Filters to Search
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='row'>
        <Spin size='large' spinning={isLoading || isFetching || isFileLoading}>
          <Table
            columns={columns}
            className={isLoading ? 'loading' : ''}
            dataSource={data}
            expandable={{
              // eslint-disable-next-line react/display-name
              expandedRowRender: (record: IInvoicesTable) =>
                record.invoiceType === 0 && record.relatedInvoiceNumber ? <CreditNotes data={record} /> : null,
              rowExpandable: (record: IInvoicesTable) =>
                record.invoiceType === 0 && record.relatedInvoiceNumber !== null,
            }}
            pagination={false}
            style={{ width: '100%' }}
            onChange={handleTableChange}
          />
        </Spin>
      </div>

      <div className='row px-50'>
        <div>
          {dataCount > 0 && (
            <Button type='primary' onClick={handleExportCsv}>
              Download CSV Invoices
            </Button>
          )}
        </div>
        {dataCount > 10 && (
          <Paginator
            selectedPage={selectedPage}
            total={dataCount}
            onChangePage={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>
    </section>
  );
};

export default Invoices;
