import AxiosRepository from './axios.repository';
import { AxiosInstance } from 'axios';
import { ILogsRepository } from '../interfaces/LogsRepository';
import { ILog, ILogsResponse } from '../interfaces/LogsAPI';
import FileSaver from 'file-saver';

class LogsRepository extends AxiosRepository implements ILogsRepository {
  public constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  public async getAllLogs(
    page: number,
    pageSize: number,
    searchValue: string,
    startDate: string,
    endDate: string,
    order: string
  ): Promise<ILogsResponse> {
    const params: { [key: string]: string } = {};
    if (searchValue) params.search = searchValue;
    if (order) params.ordering = order;

    let url = '/logs/';
    if (startDate && endDate) url += `?start_timestamp=${startDate}&end_timestamp=${endDate}`;

    const { data } = await this.get(url, {
      params: { page, page_size: pageSize, ...(Object.keys(params).length ? params : {}) },
    });

    return data;
  }

  public async getLogByInvoiceId(invoiceId: string): Promise<ILog[]> {
    const { data } = await this.get(`/invoices/${invoiceId}/history`);

    return data;
  }

  public async getLogsCsv(searchValue: string, startDate: string, endDate: string): Promise<void> {
    const params: { [key: string]: string } = {};
    if (searchValue) params.search = searchValue.trim();
    params.export = 'export';

    let url = '/logs/';
    if (startDate && endDate) url += `?start_timestamp=${startDate}&end_timestamp=${endDate}`;

    const { data, headers } = await this.get(url, {
      params: { ...(Object.keys(params).length ? params : {}) },
      responseType: 'blob',
    });

    const filename = headers['content-disposition'].split(';')[1].split('=')[1].split('"')[1].trim();
    FileSaver.saveAs(data, filename);
  }
}

export default LogsRepository;
