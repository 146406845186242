import React from 'react';
import './styles.scss';
import moment from 'moment';

import { DatePicker } from 'antd';
import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';

interface IDatePickerInput {
  bordered?: boolean;
  className?: 'outline';
  dateFormat?: string;
  value?: string;
  onChange?: (date: moment.Moment | null, dateString: string) => void;
}

const DatePickerInput: React.FC<IDatePickerInput> = ({
  bordered = true,
  className = 'outline',
  dateFormat = 'DD/MM/YYYY',
  value,
  onChange,
}: IDatePickerInput) => {
  return (
    <>
      <div className='date-picker-container'>
        <DatePicker
          allowClear={false}
          bordered={bordered}
          className={className}
          value={value ? moment(value, moment.ISO_8601) : undefined}
          format={dateFormat}
          suffixIcon={<Calendar />}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default DatePickerInput;
