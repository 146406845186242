import AxiosRepository from './axios.repository';
import { AxiosInstance } from 'axios';
import { IGuesthouse, IGuesthousesResponse, IGuesthouseUpdate } from '../interfaces/GuesthousesAPI';
import { IGuesthousesRepository } from '../interfaces/GuesthouseRepository';

class GuesthousesRepository extends AxiosRepository implements IGuesthousesRepository {
  public constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  public async getAllGuesthouses(
    page: number,
    pageSize: number,
    searchValue: string,
    order: string
  ): Promise<IGuesthousesResponse> {
    const params: { [key: string]: string } = {};
    if (searchValue) params.search = searchValue.trim();
    if (order) params.ordering = order;

    const { data } = await this.get('/locations/clearing-house/guesthouse/', {
      params: { page, page_size: pageSize, ...(Object.keys(params).length ? params : {}) },
    });

    return data;
  }

  public async updateGuesthouseById(guesthouse: IGuesthouseUpdate): Promise<IGuesthouse> {
    const { id, expiry_date, external_identifier } = guesthouse;

    const { data } = await this.put(`/locations/clearing-house/guesthouse/${id}/`, {
      expiry_date,
      external_identifier,
    });

    return data;
  }
}

export default GuesthousesRepository;
