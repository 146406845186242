import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Button, Form, notification, Table } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import { DatePickerInput, TextInput } from '../../../components';

import { IGuesthouseTable } from '../../../common/interfaces/GuesthouseTable';
import { IGuesthouseUpdate } from '../../../common/interfaces/GuesthousesAPI';

interface IEditableCell {
  children: React.ReactNode;
  dataIndex: string | string[];
  editing: boolean;
  index?: number;
  record: IGuesthouseTable;
  title?: string;
}

const EditableCell: React.FC<IEditableCell> = ({ children, dataIndex, editing, record, ...props }: IEditableCell) => {
  let inputNode;

  switch (dataIndex) {
    case 'expiry_date':
      inputNode = <DatePickerInput bordered={false} dateFormat='DD/MM/YYYY' value={record.expiry_date} />;
      break;
    default:
      if (Array.isArray(dataIndex)) {
        inputNode = <TextInput className='material' placeholder={`Type your ${dataIndex[1]}`} width={145}></TextInput>;
      }
      break;
  }

  return (
    <td {...props}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: '' }]} {...props}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface IEditableTable {
  className: string;
  data: IGuesthouseTable[];
  onChange?: (p: unknown, f: unknown, sorter: unknown) => void;
  onEdit?: (value: boolean) => void;
  onSave?: (record: IGuesthouseUpdate) => void;
}

const EditableTable: React.FC<IEditableTable> = ({ className, data, onChange, onEdit, onSave }: IEditableTable) => {
  const [dataSource, setDataSource] = useState<IGuesthouseTable[]>([]);
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const isEditing = (record: IGuesthouseTable) => record.id === editingKey;

  const onEditHandler = (record: IGuesthouseTable) => {
    onEdit && onEdit(true);
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const onCancelHandler = () => {
    onEdit && onEdit(false);
    setEditingKey(null);
  };

  const onSaveHandler = async (id: number) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const newItem = {
          ...newData[index],
          ...row,
          ...(Object.keys(row).includes('expiry_date') ? { expiry_date: row.expiry_date ? row.expiry_date : '' } : {}),
        };

        newData.splice(index, 1, newItem);
        setDataSource(newData);
        setEditingKey(null);
        onSave && onSave(newItem);
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey(null);
      }
    } catch (errInfo) {
      notification.error({ message: 'Fill in all fields' });
      //console.error('Validate Failed:', errInfo);
    }
  };

  const getAgencySpecificColumns = () => {
    const obj = data.find((o) => o.external_identifier);
    const externalIdentifier = obj?.external_identifier;

    const agencySpecificColumns: {
      dataIndex: string | string[];
      editable: boolean;
      key?: string;
      title?: string;
      sortDirections?: SortOrder[];
      sorter?: boolean;
      width: number;
      render: (record: string) => { children: JSX.Element };
    }[] = [];

    if (externalIdentifier) {
      const obj = Object.keys(externalIdentifier);
      const columnWidth = obj.length > 1 ? 175 : 225;

      obj.map((key, idx) => {
        agencySpecificColumns.push({
          dataIndex: ['external_identifier', `${key}`],
          editable: true,
          title: !idx ? 'Agency Specific ID' : '',
          width: columnWidth,
          render: (record: string) => ({
            children: (
              <TextInput
                className='material'
                placeholder={`Type your ${key}`}
                value={record}
                width={columnWidth - 30}
              ></TextInput>
            ),
          }),
        });
      });
    }

    if (obj?.hasOwnProperty('expiry_date')) {
      agencySpecificColumns.push({
        dataIndex: 'expiry_date',
        editable: true,
        key: 'expiry_date',
        title: 'Agency Specific ID \n Expiration Date',
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        sorter: true,
        width: 148,
        render: (record: string) => ({
          children: <span>{record ? moment(record).format('DD/MM/YYYY') : ''}</span>,
        }),
      });
    }

    return agencySpecificColumns;
  };

  const columns = [
    {
      dataIndex: 'guesthouse_id',
      key: 'guesthouse_id',
      title: 'Guesthouse \n ID',
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
      width: 90,
    },
    {
      dataIndex: 'city_name',
      key: 'guesthouse_city',
      title: 'Guesthouse \n Location',
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
    },
    {
      dataIndex: 'country_name',
      key: 'guesthouse_country',
      title: 'Guesthouse \n Country',
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
    },
    ...getAgencySpecificColumns(),
    {
      key: 'actions',
      title: '',
      width: 70,
      render: (record: IGuesthouseTable) => {
        const btn = isEditing(record) ? (
          <div className='actions'>
            <Button className='btn-small btn-save' size='small' type='primary' onClick={() => onSaveHandler(record.id)}>
              Save
            </Button>
            <Button className='btn-small btn-cancel' size='small' type='default' danger onClick={onCancelHandler}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            className='btn-small btn-edit'
            disabled={editingKey !== null}
            size='small'
            type='primary'
            onClick={() => onEditHandler(record)}
          >
            Edit
          </Button>
        );

        return {
          children: btn,
        };
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.hasOwnProperty('editable')) {
      return col;
    }

    return {
      ...col,
      onCell: (record: IGuesthouseTable) => ({
        editing: isEditing(record),
        dataIndex: col.dataIndex,
        record,
        title: col.title,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        className={className}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={dataSource}
        pagination={false}
        rowClassName='editable-row'
        rowKey={(record) => record.id}
        style={{ width: '100%' }}
        onChange={(p: unknown, f: unknown, sorter: unknown) => onChange && onChange(p, f, sorter)}
      />
    </Form>
  );
};

export default EditableTable;
