import React, { useEffect, useState } from 'react';

import { getSwitches } from './api';

import warning from './assets/warning.png';

import './Maintenance.scss';

interface MaintenanceProps {
  switchName: string;
  className?: string;
}

interface Switch {
  is_active: boolean;
  note: string;
}

export default function Maintenance({ switchName, className }: MaintenanceProps) {
  const [switches, setSwitches] = useState<Record<string, Switch>>({});
  const [isClosed, setIsClosed] = useState(() => sessionStorage.getItem(switchName));

  useEffect(() => {
    getSwitches().then((res) => setSwitches(res.switches));
  }, []);

  const maintenanceSwitch = switches[switchName];

  if (!maintenanceSwitch?.is_active || isClosed) {
    return null;
  }

  const handleClose = () => {
    setIsClosed('true');
    sessionStorage.setItem(switchName, 'true');
  };

  return (
    <div className={`maintenance-wrapper ${className || ''}`}>
      <img src={warning} alt='warning' />
      <div className='maintenance-note'>{maintenanceSwitch.note}</div>
      <div className='close-button' onClick={handleClose}>
        X
      </div>
    </div>
  );
}
