import React, { useEffect } from 'react';
import './styles.scss';
import { deleteTokens } from '../../common/utils/pkce';

export const Logout: React.FC = () => {
  useEffect(() => {
    deleteTokens();
  }, []);

  return <section className='login-container'></section>;
};

export default Logout;
