import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LocalStorageKeys } from '../enum';
import { notification } from 'antd';
import { refreshAccessToken } from './pkce';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_DEV_URL}${process.env.REACT_APP_API_DEV_VERSION}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (value: AxiosRequestConfig) => {
  const params = (value.url && value.url.split('/')) || [];
  const isGuesthouse = params.includes('guesthouse') || params.includes('check-permissions');
  const expirationDate = localStorage.getItem('expiration');
  const currentTimeSeconds = Math.round(+new Date() / 1000);

  if (expirationDate && +expirationDate < currentTimeSeconds) {
    await refreshAccessToken();
  }

  if (isGuesthouse) {
    value.baseURL = `${process.env.REACT_APP_BASE_URL}api/${process.env.REACT_APP_API_VERSION}`;
    value.headers = {
      ...value.headers,
      Authentication: localStorage.getItem(LocalStorageKeys.ID_TOKEN),
    };
    value.withCredentials = true;
  } else {
    value.headers = {
      ...value.headers,
      'Authorization-Clearing-House': localStorage.getItem(LocalStorageKeys.TOKEN),
      'X-Api-Key': process.env.REACT_APP_API_KEY,
    };
  }

  return value;
});

axiosInstance.interceptors.response.use(
  async (value) => value,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      const accessToken = localStorage.getItem('accessToken');
      const idToken = localStorage.getItem('idToken');

      if (accessToken && idToken) {
        await refreshAccessToken();
      } else {
        window.location.href = '/forbidden';
      }
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      originalRequest.headers['Authorization-Clearing-House'] = accessToken;
      return axiosInstance(originalRequest);
    }

    if (!Object.keys(error.response.data)) {
      return Promise.reject(error);
    }

    const errorsKeys = Object.keys(error.response.data);

    if (errorsKeys.length && error.response.status === 400) {
      notification.error({ message: error.response.data[errorsKeys[0]][0] });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
