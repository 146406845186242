import React from 'react';
import './styles.scss';

import { logout } from '../../common/utils/pkce';

import { Button } from 'antd';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as WfpLogo } from '../../assets/svg/agencies/wfp-logo.svg';

const Header: React.FC = () => {
  const onLogoutHandler = (): void => {
    logout();
  };

  return (
    <section className='header'>
      <Logo />
      <div className='status-bar'>
        <div className='status-container'>
          <WfpLogo height='100%' width='50px' />
          <span>
            Hello <b>{localStorage.getItem('username')}</b>,
            <Button type='link' onClick={onLogoutHandler}>
              Logout
            </Button>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Header;
